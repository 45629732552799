.epar {
  &.components{
    &.dashboard-button-col {
        padding: 3%;

        .tool {
            background: #efeeee;
        }

        .tool-label {
            text-align: center;
            p {
                font-size: 12pt;
            }

            button.ant-btn.ant-btn-circle.ant-btn-lg.ant-btn-icon-only {
              height: 100%;
              width: 100%;
              background: transparent;
              border: none;
            }
        }
    }
  }
}
