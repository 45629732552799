.epar__details {
  
  &__view {
    background: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
 
  &__table {
    border-radius: 0px;
    padding-left: 30px;
  }

  &__table--item {
    width: 100%;
  }

  &__edit--button  {
    width: 100%;
    margin-bottom: 10px;
  }
}


