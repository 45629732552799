.tr-video-player-modal .ant-modal-body {
    padding: 0 !important;
  }
  
  .ind-video-player {
    background: #222;
  }
  
  .tr-video-player-modal .ant-modal-close {
    color: white;
    width: 40px;
    height: 40px;
    border: 1px solid #444;
    background: #444;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -35px;
    font-size: 18px;
  }

  .training-drggr {
    width: 500px !important;
    margin: auto;
    display: block!important;
    .ant-upload{
      display: block!important;  
      .ant-upload-drag-container {
        display: block!important;
      }
    }
  }

  .trainvd-custom-image {
    cursor: pointer;
    .tn-play-icon {
      font-size: 35px;
      position: absolute;
      background: #ffffff87;
      border-radius: 50%;
      top: 29%;
      left: 45%;
    }
  }

  .trn-action-button {
    float: right;
  }

  .trn-video-details {
    margin-bottom: 10px;
    .trn-detail-lbl {
      font-weight: 600;
    }
    .video-lbl-row {
      background-color: whitesmoke;
      .video-lbl {
        margin: 5px 0px 5px 0px;
      }
      .video-icon {
        float: right;
      }
    }
  }