.epar__list-widget {
    &__avatar {     
        i {
            color: white !important;
            font-size: 23px;
          }
    }
    
    &__card {  
        max-height: 600px;
        overflow:auto
    }
    .ant-list-item-content {
        max-width: 100px;
    }
    .ant-list-item-meta-avatar{        
        margin-top: auto;
        margin-bottom: auto; 
    }

}