.certain-category-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
    right: 12px;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-weight: bold;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #F6F6F6;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item {
    padding-left: 16px;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
    text-align: center;
    cursor: default;
  }
  
  .certain-category-search-dropdown .ant-select-dropdown-menu {
    max-height: 300px;
  }
  
  .certain-search-item-count {
   position: absolute;
   color: #999;
   right: 16px;
  }
  
  .certain-category-search.ant-select-focused .certain-category-icon {
    color: #108ee9;
  }
  
  .certain-category-icon {
    color: #6E6E6E;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 16px;
  }
