.ehs-calendar{
  .ant-fullcalendar-header{
    div:nth-child(3){
      display:none;
    }
  }
}
.tox-notification--warning { // To remove Tiny mce Editor API missing warning
display: none !important;
}
.tox-notification--error { // To remove Tiny mce Editor Image not found error
display: none !important;
}