.pure-g [class*=pure-u] {
  font-family: "Helvetica Neue For Number","-apple-system,system-ui","BlinkMacSystemFont","Segoe UI","Roboto,PingFang SC","Helvetica Neue",Helvetica,Arial,sans-serif !important;
  font-size: 14px !important;
  font-size: 20px;
  color: rgba(0,0,0,.85);
}

body {
  font-family: "Helvetica Neue For Number","-apple-system,system-ui","BlinkMacSystemFont","Segoe UI","Roboto,PingFang SC","Helvetica Neue",Helvetica,Arial,sans-serif !important;
}
