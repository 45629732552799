.epar-user-admin {
    .users-table {
        background: rgb(255, 255, 255);    

        .table-header {
            background-color: #e6e6e6;
            text-align: left;
            padding: 4px;
            color:#989898!important;
        }

        .table-body {
            border-bottom: 1px solid black;
            padding: 6px;
            color:#66a4b8
        }
    }

    .add-user {
        .permission-table-header {
            background: black;
            color: white;
        }
    }

    .epar-user-admin-button-container{
        float: right;
        width: 350px;
        padding: 20px;
        padding-bottom: 30px;

        .pure-u-1-5{
            width:33%;

            .add-org-unit-type-btn{
                width:80px;
            }
        }

        .add-org-unit-type-btn{
            border: solid 1px #66a4b8 ;
            color: #66a4b8;
            padding: 6px;
            text-align: center;
        }
    }
    
    .clearToolbar {clear:both}
}

.epar {
    font-family: "Helvetica Neue" For Number,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,PingFang SC,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
    &__table{
        &--header-row {
            background-color: #e6e6e6;
            text-align: left;
            padding: 4px;
            color:#989898!important;
        }
        &--content-row {
            border-bottom: 1px solid darkgray;
            padding: 6px;
            color:#66a4b8
        }
        &--content-row-text {
            padding: 6px;
            color:#66a4b8
        }
    }
}

