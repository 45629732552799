@import "../abstracts/variables.scss";

.epar {
  &__loading__container {
    height: 100%;
    width: 100%;
    margin: 0px;
    background: #001529;
    position: fixed;
  }
  &__loading--spinner {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    top: 30%;
    position: fixed;
    color: white;
  }

  &__list__content--card {
    padding: 5px;
  }
}

.root-container, .epar-homepage {
  overflow-y: auto;
}

.sup-beta {
  vertical-align: super;
  font-size: 0.4em;
}

.epar--link {
  color: $eparColor !important;
}

.epar__action--button {
  background: white;
  color: $eparLogoColor;
}

.epar__label--error {
  color: red;
}

.epar__label--success {
  color: green;
}

.epar__action__danger--icon-buttons {
  background: red;
}

.epar__action__primary--icon-buttons {
  background: #1890ff;
}

.epar__status {
  &__not-done {
    background: red;
  }

  &__done {
    background: green;
  }
}

.epar__tasks__action--button {
  float: right;
  background-color: white;
  border-color:#d9d9d9;
  color: rgba(0,0,0,.65);
  margin-left: 10px;
}
