.epar__sider {
  i.anticon {
    color: white;
  }
}

.epar__side__drawer {
  .ant-drawer-header, .ant-drawer-body {
    padding: 0px;
  }

  .ant-drawer-content{
    background: #001529;
  }
  
  i.anticon {
    color: white;
  }
}