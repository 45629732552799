.breadcrumb__header {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: solid 1px #d9d9d9;
}

.div__title {
  height: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 30px;
}

.title__view {
  background: transparent;
  font: bold;
  font-size: 24px;
  font-weight: 400;
  color: rgba(0,0,0,.85);   
}

.path__view {
  font: bold;
  font-size: 14px;
  color: rgba(0,0,0,.85);     
}
