.epar_national_map {
    &_form_category {
        font-weight: 700;
        font-size: 16px;
    }

    &_ou-group {
        cursor: pointer;
        justify-content: space-between;
        transition: 0.5s;
        height: 40px;
        font-weight: 400;

        &:hover {
            height: 50px;
            font-weight: 600;
        }
    }

    &_delete-icon {
        font-size: 14px;
        transition: 0.5s;
        color: rgba(0, 0, 0, 0.65);

        &:hover {
            font-size: 16px;
            color: rgba(55, 53, 53, 0.65);
        }
    }

    .ant-form-item-label {
        label {
            font-weight: 400;
        }
    }

    &_submit-button {
        background-color: $eparSelecetdItemBackgroundColor;
        color: white;
        margin-left: 10px;
        border: none;
    }

    &_label {
        padding-right: 20px;
    }

    &_row {
        margin-block: 10px;
    }

    &_radio-button {
        font-weight: 400;
    }

    .ant-radio-button-wrapper-checked {
        background-color: $eparSelecetdItemBackgroundColor;
        color: white;
    }

    .ant-spin-container {
        ul {
            padding: 0px;
            margin: 0px;
        }
    }

    &_detail-view {
        &_row {
            padding-block: 10px;
        }

        .detail-title {
            padding-top: 20px;
        }

        .detail-properties {
            text-align: right;
            padding-right: 10px;
        }
    }

    &_edit {
        &_form {
            height: 100vh;
        }
    }

    &_image {
        width: 100px;
        height: 80px;
        object-fit: contain;
    }

    &_image-loading {
        width: 100px;
        height: 80px;
        position: absolute;
        z-index: 10;
        background-color: white;
        text-align: center;
    }
}