
.epar__ouadmin {
  &__ouview {
    &__button-container {
      border: none;
      // padding: 5px;
    }
    &__container {
        background: white;
        // margin: 5px !important;
        padding: 5px;
    }
    &--ou-button {
      background: $eparLogoColor !important;
      color: white;
      // width: 175px;
      // padding: 5px;
    }
    &--ou-button:hover {
      color: yellow;
    }
    &--outype-button {
      float: right;
    }
    &__home-icon {
      font-size: 16px !important;
      color: $eparLogoColor;
    }
  }
}


