$eparColor: #218fa9;
$eparMenuItemColor: #fafafa;
$eparHeaderBackgroundColor: #0293ad;
$eparLogoColor: #002140;
$eparSideMenuBackgrounColor: #001529;

$eparButtonBackgroundColor: #211f1e;
$eparSelecetdItemBackgroundColor: #1890ff;
$eparTableHeaderBackgroundColor: #fafafa;
$eparSidePanelCollapseButtonHoverColor: #e6f7ff;
$eparViewBackgroundColor: rgb(240, 243, 246);