.epar {

  &__login__form-header {
    text-align: center;
    margin-top: 20pt;
    color: gray;
    background: white;
  }
  &__validation-label {
    font-size: 14px;
    color: red;
  }
  &__login__form {
    padding-left: 10%;
    padding-right: 10%;
    background: white;
    margin-bottom: 20px;

  }
  &__login__form--logo  {
    width: 300px;
  }
  &__login__form--label {
    padding: 15px;
  }
  &__login-form__submit--button {
    width: 200px;
  }
  &__login-form__field > input {
    width: 200px;
  }
  &__login__form__progressbar .ant-progress-bg {
    height: 4px !important;
    background-color: #52c2cd;
    border-radius: 0px;
  }
}


.epar__login {
  height: 100%;
}

.epar__login__container {
  height: 100%;
}

.epar__login__form__container {
  box-shadow: 14px 24px 38px -24px rgba(0,0,0,0.75);

  .ant-layout {
    background: white;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  &__forgot-password {
    text-align: center;
  }

  &__agree-terms {
    display: block;
    text-align: center;
  }
}

.epar__login__forgot-password-form {

  &__container{
    box-shadow: 14px 24px 38px -24px rgba(0,0,0,0.75);
    background: white;
    padding: 10px 40px 40px 40px;
  
    .ant-layout {
      background: white;
    }

    .ant-btn {
      background-color: #1890ff;
      color: white;
    }
  }

  &__center_content {
    text-align: center;
    padding-top: 10px;
    input, button, span {
      margin: 15px 10px 15px 10px;
    }

  }

  &__have-code {
    margin-top: 30px;
    font-weight: normal;
  }

}

.epar__reset-password-form {
  padding-top: 5px;
  background: #f0f2f5;

  .epar__login__form-header {
    padding-top: 15px;  
  }

  .epar__login__form {
    padding-bottom: 15px;  
  }

  .ant-form-item-control {
    line-height: 25px;
  }

  .ant-btn {
    background-color: #1890ff;
    color: white;
  }
}

.epar__login__new-password-form {
  .epar__login__form-header {
    height: 100%;
  }
  .ant-btn {
    background-color: #1890ff;
    color: white;
  }

  .ant-form-item-control {
    line-height: 20px;
  }

}

.epar__login__user-agreement__text {
  font-weight: normal;
}

.epar__user__agreement__button{
  padding-left: 50%;
}