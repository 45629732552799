.epar__toolbar--row {
  margin-bottom: 10px;
}

.epar__view--title {
  font-size: 20px;
  /*font-weight: bold;*/
  color:#000; 
}

.epar__wizard--wrapper {
  margin: 5px;
  padding: 5px;
}

.epar__wizard-content-wrapper {
  margin-top: 20px;
}

.epar__content-wrapper {
  margin: 20px 24px 0px;
  height: 100%;
}

.epar__header {
  // height: 64px;
  height: 100%;
  border-bottom: solid 1px #e9e9e9;


  span.ant-select-selection__rendered, sup.ant-scroll-number.ant-badge-count.ant-badge-multiple-words {
    letter-spacing: 0px;
  }
  
}

.epar__action--button > i {
  color: #002140;
}
.epar__action--button:active, .epar__action--button:focus, .epar__action--button:hover  {
  // background: $eparSelecetdItemBackgroundColor;
  color: $eparButtonBackgroundColor;
}

.epar__task__details--table{
  font-size: 12px;
  padding: 0px;
  margin: 0px;
}

.epar__task__details--table > tr{
    margin: 0px;
    padding: 9px !important;
}

.epar__content__taskbar {
  margin-bottom: 10px;
}

.epar__dashboard--card {
  padding: 0px;
}

.anticon-filter {
  margin-left: 4px;
  margin-bottom: 7px;
}

.epar__user-card-head {
  margin-bottom: 20px;
}

.epar__user-card-body {
  border-top: 1px solid #e8e8e8;
  padding: 10px 0px;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  height: 100%;
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}

.tabContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-name {
  padding-left: 10%;
}

.edit-button-user{
  padding-left: 40%;
}

.edit-button-preferences {
  float: right;
}

.user-avatar-profile {
  font-size: 0px;
  i.anticon.anticon-user{
    font-size: 40px;
  }
}

.task_search {
width: 50%
}
